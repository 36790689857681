import api from '@/core/client';
import { BillingCyclePeriodEnum, StoreSubscriptionDto } from '@/generated/typing';
import { Tag } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { useUserContext } from '@/contexts/user-context';
import { format } from 'date-fns';
import Title from '../ui/Title';
import { HiDocumentCheck } from 'react-icons/hi2';
import UserName from '../ui/user/UserName';
import Panel from '../ui/Panel';
import { getColorStatus } from '@/utils/subscription/get-color-status';
import Link from 'next/link';
import { useTranslation } from '@/hooks/useTranslation';
import { useMemo } from 'react';
import { List } from '@/components/list/List';

const columnHelper = createColumnHelper<StoreSubscriptionDto>();

const SalesList = () => {
    const { activeStore } = useUserContext();
    const { t } = useTranslation(['subscription', 'product', 'contract', 'common']);

    const columns = useMemo(
        () => [
            columnHelper.accessor(
                (row) => `${row.dealer?.firstName ?? ''} ${row.dealer?.lastName ?? ''}`,
                {
                    id: 'dealerName',
                    header: () => t('subscription:dealer_name'),
                    cell: ({ renderValue }) => {
                        const value = renderValue();
                        return value === ' ' || !value ? null : (
                            <UserName user={{ name: renderValue()! }} />
                        );
                    },
                },
            ),
            columnHelper.accessor((row) => row.contract.product.name, {
                id: 'name',
                header: () => t('product:product'),
                cell: ({ cell, renderValue }) => (
                    <Link
                        href={`/stores/${activeStore?.id ?? ''}/subscriptions/${
                            cell.row.original.id
                        }`}
                    >
                        {renderValue()}
                    </Link>
                ),
            }),
            columnHelper.accessor((row) => row.salesChannel.name, {
                id: 'salesChannelName',
                header: () => t('subscription:sales_channel'),
                cell: ({ cell, renderValue }) => (
                    <Link
                        href={`/stores/${activeStore?.id ?? ''}/subscriptions/${
                            cell.row.original.id
                        }`}
                    >
                        {renderValue()}
                    </Link>
                ),
            }),

            columnHelper.display({
                id: 'billingCyclePeriod',
                header: () => t('subscription:billing_cycle_period'),
                cell: ({ cell }) => {
                    const billingCyclePeriods = cell.row.original.contract.product.services.flatMap(
                        (service) => service.billingCyclePeriod,
                    );
                    return (
                        <>
                            {billingCyclePeriods.includes(BillingCyclePeriodEnum.Month) && (
                                <Tag colorScheme="blue">{t('subscription:monthly')}</Tag>
                            )}
                            {billingCyclePeriods.includes(BillingCyclePeriodEnum.OneShot) && (
                                <Tag colorScheme="blue">{t('subscription:one_shot')}</Tag>
                            )}
                        </>
                    );
                },
            }),

            columnHelper.accessor((row) => format(new Date(row.insuranceDate), 'dd/MM/yyyy'), {
                id: 'orderDate',
                header: () => t('subscription:subscription_date'),
                cell: ({ cell, renderValue }) => (
                    <Link
                        href={`/stores/${activeStore?.id ?? ''}/subscriptions/${
                            cell.row.original.id
                        }`}
                    >
                        {renderValue()}
                    </Link>
                ),
            }),

            columnHelper.accessor((row) => `${row.amountInclTax.toFixed(2)} €`, {
                id: 'amount',
                header: () => t('subscription:tax_amount'),
                cell: ({ renderValue }) => renderValue(),
            }),

            columnHelper.accessor((row) => row.status as string, {
                id: 'status',
                header: () => t('subscription:status'),
                cell: ({ row }) => {
                    return (
                        <Tag colorScheme={getColorStatus(row.original)}>
                            {t(`subscription:status_list.${row.original.status}`)}
                        </Tag>
                    );
                },
            }),
        ],
        [activeStore?.id, t],
    );

    if (!activeStore) {
        return null;
    }
    return (
        <>
            <Title title={t('dashboard:last_sales')} IconComponent={HiDocumentCheck} />
            <Panel>
                <List
                    columns={columns}
                    query={async () =>
                        api.stores.getStoreSubscriptions(activeStore?.id, {
                            limit: 10,
                            order: 'DESC',
                        })
                    }
                    disablePagination
                    queryCacheName={['stores', activeStore?.id, 'subscriptions']}
                />
            </Panel>
        </>
    );
};

export default SalesList;
